import { connect } from "react-redux"
import React, { Component } from "react"
import { pure, compose } from "recompose"
import { graphql } from "gatsby"
import classNames from "classnames"

import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { withStyles } from "@material-ui/core/styles"

import * as masterActions from "../actions/master"

type Props = {
  requestProcess: Function
  completeProcess: Function
  isProcessing: boolean
  data: any
  wpModel: any
  classes: any
}

type State = {
  checked: boolean
}

class ContactPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      checked: false,
    }
  }

  _handleChange = (name: string) => (event: any) => {
    this.setState({
      [name]: event.target.checked,
    })
  }

  render() {
    const { data, classes } = this.props

    const year =
      "1920 1921 1922 1923 1924 1925 1926 1927 1928 1929 1930 1931 1932 1933 1934 1935 1936 1937 1938 1939 1940 1941 1942 1943 1944 1945 1946 1947 1948 1949 1950 1951 1952 1953 1954 1955 1956 1957 1958 1959 1960 1961 1962 1963 1964 1965 1966 1967 1968 1969 1970 1971 1972 1973 1974 1975 1976 1977 1978 1979 1980 1981 1982 1983 1984 1985 1986 1987 1988 1989 1990 1991 1992 1993 1994 1995 1996 1997 1998 1999 2000 2001 2002 2003 2004 2005 2006 2007 2008 2009 2010 2011 2012 2013 2014 2015 2016 2017 2018 2019"
    const month = "1 2 3 4 5 6 7 8 9 10 11 12"
    const day =
      "1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31"

    const subject = [
      "企業研修・講演",
      "取材のお申込み",
      "採用について",
      "その他",
    ]

    return (
      <Layout className="pt20">
        <SEO title="お問い合わせ" />
        <div className={classes.root}>
          <div className={classes.componentWrapper}>
            <section id="privacy">
              <div className="title-wrapper pt20">
                <h1 className={classNames("title", classes.title)}>
                  お問い合わせ
                </h1>
              </div>
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="bot-field" />
                <FormLabel component="legend" className={classes.requiredLabel}>
                  * 必須項目
                </FormLabel>

                <div className="mt20 mb20">
                  <FormLabel component="legend" className={classes.selectLabel}>
                    お問い合わせ項目 *
                  </FormLabel>
                  <div>
                    <FormControl fullWidth={true}>
                      <InputLabel htmlFor="subject">
                        選択してください
                      </InputLabel>
                      <Select
                        native
                        inputProps={{
                          name: "subject",
                          id: "subject",
                        }}
                      >
                        <option value="" />
                        {(() => {
                          return subject.map((sub: string, i: number) => {
                            return (
                              <option key={`subject-${i}`} value={sub}>
                                {sub}
                              </option>
                            )
                          })
                        })()}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <FormControl fullWidth={true}>
                  <TextField
                    label="会社名または団体名"
                    id="company"
                    name="company"
                    margin="normal"
                    required
                    className="mt20 mb20"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    label="お名前"
                    id="name"
                    name="name"
                    margin="normal"
                    required
                    className="mt20 mb20"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    label="メールアドレス"
                    id="email"
                    type="email"
                    name="email"
                    //pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                    margin="normal"
                    required
                    className="mt22 mb20"
                  />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField
                    label="電話番号"
                    id="tel"
                    name="tel"
                    margin="normal"
                    className="mt20 mb20"
                  />
                </FormControl>
                <div className="mt20 mb20">
                  <FormLabel component="legend" className={classes.selectLabel}>
                    生年月日
                  </FormLabel>
                  <div className={classes.birthday}>
                    <div className={classes.itemWidth}>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="birth-year">年</InputLabel>
                        <Select
                          native
                          inputProps={{
                            name: "birth-year",
                            id: "birth-year",
                          }}
                        >
                          <option value="" />
                          {(() => {
                            return year.split(" ").map(y => {
                              return (
                                <option key={`year-${y}`} value={y}>
                                  {y}
                                </option>
                              )
                            })
                          })()}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.itemWidth}>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="birth-month">月</InputLabel>
                        <Select
                          native
                          inputProps={{
                            name: "birth-month",
                            id: "birth-month",
                          }}
                        >
                          <option value="" />
                          {(() => {
                            return month.split(" ").map(m => {
                              return (
                                <option key={`month-${m}`} value={m}>
                                  {m}
                                </option>
                              )
                            })
                          })()}
                        </Select>
                      </FormControl>
                    </div>
                    <div className={classes.itemWidth}>
                      <FormControl fullWidth={true}>
                        <InputLabel htmlFor="birth-day">日</InputLabel>
                        <Select
                          native
                          inputProps={{
                            name: "birth-day",
                            id: "birth-day",
                          }}
                        >
                          <option value="" />
                          {(() => {
                            return day.split(" ").map(d => {
                              return (
                                <option key={`day-${d}`} value={d}>
                                  {d}
                                </option>
                              )
                            })
                          })()}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="pt20 pb20">
                  <FormLabel component="legend" className={classes.selectLabel}>
                    性別
                  </FormLabel>
                  <RadioGroup className={classes.gender} name="gender">
                    <FormControlLabel
                      value="男性"
                      control={<Radio />}
                      label="男性"
                      className={classes.itemWidth}
                    />
                    <FormControlLabel
                      value="女性"
                      control={<Radio />}
                      label="女性"
                      className={classes.itemWidth}
                    />
                    <FormControlLabel
                      value="選択しない"
                      control={<Radio />}
                      label="選択しない"
                      className={classes.itemWidth}
                    />
                  </RadioGroup>
                </div>
                <FormControl fullWidth={true} className="mt20 mb20">
                  <div className="mb20">
                    お問い合わせ内容を400字以内で入力してください
                  </div>
                  <TextField
                    multiline
                    rows={15}
                    id="message"
                    name="message"
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.bootstrapRoot,
                        input: classes.bootstrapInput,
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.bootstrapFormLabel,
                    }}
                  />
                </FormControl>
                <div className="mt20 mb40">
                  <span>
                    <a
                      className={classes.privacy}
                      href="/privacy/"
                      target="_blank"
                    >
                      プライバシーポリシー
                    </a>
                    にご同意の上、「送信」してください。
                  </span>
                  <div className="mt20">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checked}
                          onChange={this._handleChange("checked")}
                        />
                      }
                      label="プライバシーポリシーに同意"
                    />
                  </div>
                </div>
                <div className="mt20">
                  <Button
                    disabled={!this.state.checked}
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    type="submit"
                  >
                    送信
                  </Button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    paddingTop: 40,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
  componentWrapper: {
    margin: "0 auto",
    maxWidth: 880,
    padding: "40px 80px",
    backgroundColor: "#FFF",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 20px",
    },
  },
  body: {
    maxWidth: 834,
    fontSize: 16,
    lineHeight: 1.56,
    letterSpacing: 1.6,
    color: "#808080",
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  requiredLabel: {
    textAlign: "right",
    width: "100%",
    marginBottom: 20,
    color: "red",
  },
  birthday: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  itemWidth: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  selectLabel: {
    textAlign: "left",
    marginTop: 14,
    marginBottom: 14,
    fontSize: 14,
  },
  gender: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "Noto Sans JP",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
  button: {
    fontSize: 26,
    fontWeight: "bold",
    backgroundColor: "#c12f2f",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  privacy: {
    fontSize: 20,
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  title: {
    fontFamily: "Sawarabi Mincho",
    [theme.breakpoints.down("sm")]: {
      fontSize: "34px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px !important",
    },
  },
})

export const pageQuery = graphql`
  {
    wordpressPage(id: { eq: "43396804-61f5-5588-8438-4f91e0da836e" }) {
      id
      content
      title
    }
  }
`

function mapStateToProps(state: any) {
  const { isProcessing } = state.rootReducer.master

  return {
    isProcessing,
    wpModel: state.rootReducer.wordpress,
  }
}

const connector = connect(
  mapStateToProps,
  { ...masterActions }
)

export default compose(
  pure,
  withStyles(styles),
  connector
)(ContactPage)
